import Vue from 'vue';
import Router, { RawLocation, Route, RouteConfig } from 'vue-router';
import i18n from '@/config/i18n';
import { interopDefault } from '@/utils/promise';
import blogRoutes from '@/router/blog.routes';
import permanentRoutes from '@/router/permanent.routes';
import { LIBRARY_EXPLORER_TABS, LIBRARY_SEARCH_EXPLORER_TABS } from '@/constants/pixcapassets.constants';
import { APP_AVAILABLE_LOCALE_ROUTE_PARAMS, DEFAULT_APP_LOCALE } from '@/constants/app.constants';

class PixcapRouter extends Router {
	resolve(to: RawLocation, current?: Route, append?: boolean) {
		to = this.addLocale(to);
		return super.resolve(to, current, append);
	}

	push(location: RawLocation): Promise<Route> {
		location = this.addLocale(location);
		return super.push(location);
	}

	private addLocale(location: RawLocation): RawLocation {
		if (typeof location === 'string') {
			return { path: location, params: { locale: i18n.locale !== DEFAULT_APP_LOCALE ? i18n.locale : null } };
		}

		if (typeof location === 'object' && (location?.params?.locale || location?.params?.byPassPixcapRouter)) {
			return location;
		}

		return {
			...location,
			params: { ...location.params, locale: i18n.locale !== DEFAULT_APP_LOCALE ? i18n.locale : null },
		};
	}
}

Vue.use(PixcapRouter);

const Library = () => interopDefault(import('@/layouts/Library.vue'));
const LibraryExploreList = () => interopDefault(import('@/pages/pixcap-library/LibraryExploreList.vue'));
const LibraryExploreSearchList = () => interopDefault(import('@/pages/pixcap-library/LibraryExploreSearchList.vue'));

const LibraryBrandKitPackDetailsPage = () => interopDefault(import('@/pages/pixcap-library/LibraryBrandKitPackDetailsPage.vue'));
const LibraryPackDetailsPage = () => interopDefault(import('@/pages/pixcap-library/LibraryPackDetailsPage.vue'));
const LibraryTemplateDetailsPage = () => interopDefault(import('@/pages/pixcap-library/LibraryTemplateDetailsPage.vue'));
const LibraryItemDetailsPage = () => interopDefault(import('@/pages/pixcap-library/LibraryItemDetailsPage.vue'));

const PricingPage = () => interopDefault(import('@/pages/PricingPage.vue'));
const CommunityPage = () => interopDefault(import('@/pages/CommunityPage.vue'));
const CommunityItemDetailsPage = () => interopDefault(import('@/pages/community/CommunityItemDetailsPage.vue'));
const ContactUsPage = () => interopDefault(import('@/pages/ContactUsWrapperPage.vue'));
const ProjectsPage = () => interopDefault(import('@/pages/ProjectsPage.vue'));
const SharedProjectsPage = () => interopDefault(import('@/pages/SharedProjectsPage.vue'));
const AssetsPage = () => interopDefault(import('@/pages/AssetsPage.vue'));
const LibraryPage = () => interopDefault(import('@/pages/LibraryPage.vue'));
const SitemapPage = () => interopDefault(import('@/pages/SitemapPage.vue'));

const UserSettingsPage = () => interopDefault(import('@/pages/UserSettingsPage.vue'));

const localeRegex = APP_AVAILABLE_LOCALE_ROUTE_PARAMS.join('|');
const exploreTabsRegex = LIBRARY_EXPLORER_TABS.join('|');
const searchTabsRegex = LIBRARY_SEARCH_EXPLORER_TABS.join('|');

const routes: RouteConfig[] = [
	blogRoutes,
	...permanentRoutes,
	{
		path: '/invite/:referralCode',
		redirect: (to) => ({
			name: 'LibraryExploreList',
			query: {
				referral: to.params.referralCode,
			},
		}),
	},
	{
		path: '/contact-us',
		name: 'ContactUsPage',
		meta: {
			isPublicRoute: true,
		},
		component: ContactUsPage,
	},

	{
		path: `/:locale(${localeRegex})?/pricing`,
		name: 'PricingPage',
		meta: {
			isPublicRoute: true,
		},
		component: PricingPage,
	},
	{
		path: `/:locale(${localeRegex})?/projects`,
		name: 'ProjectsPage',
		meta: {
			isPublicRoute: false,
		},
		component: ProjectsPage,
		props: (route) => ({
			search: route.query.search,
			type: route.query.type,
		}),
	},
	{
		path: `/:locale(${localeRegex})?/shared-projects`,
		name: 'SharedProjectsPage',
		component: SharedProjectsPage,
		props: (route) => ({
			search: route.query.search,
			type: route.query.type,
		}),
	},
	{
		path: `/:locale(${localeRegex})?/my-assets`,
		name: 'AssetsPage',
		meta: {
			isPublicRoute: false,
		},
		component: AssetsPage,
		props: (route) => ({
			tab: route.query.tab,
		}),
	},
	{
		path: `/:locale(${localeRegex})?/user/settings`,
		name: 'UserSettingsPage',
		meta: {
			isPublicRoute: false,
		},
		component: UserSettingsPage,
		props: (route) => ({
			tab: route.query.tab,
		}),
	},
	{
		path: '/sitemap',
		name: 'SitemapPage',
		meta: {
			isPublicRoute: true,
		},
		component: SitemapPage,
	},
	{
		path: `/:locale(${localeRegex})?`,
		component: Library,
		meta: { isLibraryLayout: true },
		children: [
			{
				path: '',
				name: 'LibraryExploreList',
				meta: {
					isPublicRoute: true,
				},
				component: LibraryExploreList,
			},
			{
				path: `:tab(${exploreTabsRegex})`,
				name: 'LibraryPage',
				meta: {
					isPublicRoute: true,
				},
				component: LibraryPage,
			},
			{
				path: `:tab(${searchTabsRegex})/:searchQuery`,
				name: 'LibraryExploreSearchList',
				meta: {
					isPublicRoute: true,
				},
				component: LibraryExploreSearchList,
			},

			{
				path: `item/:itemSlug`,
				name: 'LibraryItemDetails',
				meta: {
					isPublicRoute: true,
				},
				props: (route) => ({
					itemSlug: route.params.itemSlug,
				}),
				component: LibraryItemDetailsPage,
			},
			{
				path: '/community',
				name: 'CommunityPage',
				meta: {
					isPublicRoute: true,
				},
				component: CommunityPage,
			},
			{
				path: `/community/:itemSlug`,
				name: 'CommunityItemDetails',
				meta: {
					isPublicRoute: true,
				},
				props: (route) => ({
					itemSlug: route.params.itemSlug,
				}),
				component: CommunityItemDetailsPage,
			},
			{
				path: 'pack/:packSlug',
				name: 'LibraryPackDetailsPage',
				meta: {
					isPublicRoute: true,
				},
				component: LibraryPackDetailsPage,
				props: (route) => ({
					packSlug: route.params.packSlug,
				}),
			},
			{
				path: 'mockups-kit/:packSlug',
				name: 'LibraryBrandKitPackDetailsPage',
				meta: {
					isPublicRoute: true,
				},
				component: LibraryBrandKitPackDetailsPage,
				props: (route) => ({
					packSlug: route.params.packSlug,
				}),
			},
			{
				path: 'mockup/:itemSlug',
				name: 'LibraryMockupDetailPage',
				meta: {
					isPublicRoute: true,
				},
				component: LibraryTemplateDetailsPage,
				props: (route) => ({
					itemSlug: route.params.itemSlug,
				}),
			},
		],
	},
	{
		path: '/index.html',
		redirect: { name: 'LibraryExploreList' },
	},
];

export function createRouter() {
	const router = new PixcapRouter({
		mode: 'history',
		routes,
	});
	return router;
}
