import type { ActionContext } from 'vuex';
import logger from '@pixcap/ui-core/helpers/logger';
import { httpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import { AxiosResponse } from 'axios';
import { createSearchParams } from '@/utils/store';
import { mutationsWrapper as LibraryMutations, mutationsWrapper } from '@/store/pixcapassets/wrapper';
import {
	ActionTypes,
	LIBRARY_EXPLORE_TABS,
	LIBRARY_EXPORT_TYPE,
	LIBRARY_TAB,
	LibraryFilePlans,
	LibraryFileType,
	LibraryQueryTypes,
	PixcapLibraryFile,
	PixcapLibraryFileDetails,
	PixcapLibraryPack,
	SEARCH_ITEM_TYPE,
	SEARCH_ROUTES,
	SEARCH_TAB,
} from '@/models/store/pixcapassets.interface';
import { LIBRARY_RESOURCE_TYPE } from '@pixcap/ui-types/enums/library.enums';
import { FOR_YOU_TOPIC, LIBRARY_EXPLORER_RESOURCE_TYPES } from '@/constants/pixcapassets.constants';
import { transformSearchWord } from '@/utils/StringUtils';
import { appUtilities } from '@/modules/appUtilities';
import { UserDeviceTypes } from '@pixcap/ui-core/models/store/app.interface';

const ASSETMANAGER_PATH = API_PATHS.ASSETMANAGER_PATH;

export default {
	async [ActionTypes.VIEW_LIBRARY_ITEM](context, itemId: string) {
		try {
			await httpClient.put(`${ASSETMANAGER_PATH}/library/${itemId}/viewcount`);
		} catch {}
	},
	async [ActionTypes.EXPORT_LIBRARY_ITEM](context, { itemId, type }: { itemId: string; type: LIBRARY_EXPORT_TYPE }) {
		try {
			await httpClient.put(`${ASSETMANAGER_PATH}/library/${itemId}/addExport`, null, {
				params: {
					type,
				},
			});
		} catch {}
	},
	/// library API's

	async [ActionTypes.FETCH_LIBRARY_FILES](
		context: ActionContext<any, any>,
		payload: {
			refresh: boolean;
			page: number;
			pageSize: number;
			itemType?: string | string[];
			category?: string;
			search?: string;
			tagSearch?: string;
			resourceType?: LIBRARY_RESOURCE_TYPE;
			isPro?: boolean;
			isAnimated?: boolean;
			topic?: string;
			skipStore?: boolean;

			// thumbnail filters
			colour?: string;
			format?: string;
			minHeight?: number;
			maxHeight?: number;
			minWidth?: number;
			maxWidth?: number;
		}
	) {
		const {
			refresh,
			page,
			pageSize,
			itemType,
			category,
			search,
			tagSearch,
			resourceType,
			isPro,
			isAnimated,
			topic,
			skipStore,
			colour,
			format,
			minHeight,
			maxHeight,
			minWidth,
			maxWidth,
		} = payload;
		const stateKey = resourceType;
		if (!skipStore) mutationsWrapper.setIsFetchingLibraryFiles(context, { stateKey, isLoading: true });

		try {
			const currentPage = page;
			if (refresh) {
				mutationsWrapper.resetLibraryFilesState(context, stateKey);
			}
			const params = {
				page: currentPage,
				pageSize,
				itemType,
				category,
				search,
				tagName: tagSearch,
				topic,
				resourceType:
					resourceType === LIBRARY_RESOURCE_TYPE.ELEMENTS
						? LIBRARY_EXPLORER_RESOURCE_TYPES.filter((item) => item !== LIBRARY_RESOURCE_TYPE.CHARACTERS).join(',')
						: resourceType,
				isPro,
				isAnimated,
				colour,
				format,
				minHeight,
				maxHeight,
				minWidth,
				maxWidth,
			};
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap`, { params });
			if (response.data) {
				const { content, totalPages, totalItems } = response.data as {
					content: PixcapLibraryFile[];
					totalPages: number;
					totalItems: number;
				};
				if (skipStore) {
					return response.data;
				} else {
					mutationsWrapper.updateLibraryFilesState(context, {
						stateKey,
						listOfItems: content,
						pageSize,
						currentPage,
						totalItems,
						totalPages,
					});
				}
			}
		} finally {
			if (!skipStore) mutationsWrapper.setIsFetchingLibraryFiles(context, { stateKey, isLoading: false });
		}
	},

	async [ActionTypes.FETCH_LIBRARY_FILE_DETAILS](context: ActionContext<any, any>, itemIdentifier: string) {
		mutationsWrapper.setIsFetchingItemDetails(context, true);
		try {
			const response = await httpClient.get(
				`${ASSETMANAGER_PATH}/library/pixcap/items/slug/${itemIdentifier}?format=webp&colour=transparent&minHeight=800`
			);
			if (response.data) {
				const content = response.data as PixcapLibraryFileDetails;
				mutationsWrapper.updateLibraryItemDetails(context, content);
				return content;
			}
		} catch (err) {
			mutationsWrapper.updateLibraryItemDetails(context, null);
		} finally {
			mutationsWrapper.setIsFetchingItemDetails(context, false);
		}
		return null;
	},

	async [ActionTypes.FETCH_PACK_ITEMS](context: ActionContext<any, any>, itemId: string) {
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/packs/item/${itemId}`);
			if (response.data) {
				return response.data as {
					packItems: PixcapLibraryFile[];
					packId: string;
					packName: string;
					slug: string;
				};
			}
		} catch {}
		return [];
	},

	async [ActionTypes.FETCH_TEMPLATES_LIST](
		context: ActionContext<any, any>,
		payload: {
			refresh?: boolean;
			page?: number;
			pageSize?: number;
			tagSearch?: string;
			search?: string;
			isPro?: boolean;
			topic?: string;
			topicIncluded?: boolean;
			itemType?: string[];
			maxWidth?: number;
			maxHeight?: number;
		}
	) {
		const { refresh, page, pageSize, tagSearch, search, isPro, topic, topicIncluded, itemType, maxWidth, maxHeight } = payload;
		mutationsWrapper.setIsFetchingLibraryTemplates(context, true);
		try {
			const currentPage = refresh ? 0 : page;
			if (refresh) {
				mutationsWrapper.resetLibraryTemplatesState(context);
				mutationsWrapper.updateTemplateFilter(context, { tagSearch, search, isPro, topic, itemType });
			}
			let response: AxiosResponse<any>;
			if (search) {
				const params = {
					page: currentPage,
					search,
					pageSize,
					resourceType: itemType && itemType.length ? null : LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES,
					itemType,
					isPro,
					topic,
					maxWidth: maxWidth ?? 400,
					maxHeight,
					format: 'webp',
				};
				response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap`, { params: createSearchParams(params) });
			} else if (tagSearch) {
				const params = {
					page: currentPage,
					tagName: tagSearch,
					pageSize,
					resourceType: itemType && itemType.length ? null : LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES,
					itemType,
					isPro,
					topic,
					maxWidth: maxWidth ?? 400,
					maxHeight,
					format: 'webp',
				};
				response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap`, { params: createSearchParams(params) });
			} else {
				const params = {
					page: currentPage,
					pageSize,
					isFeatured: topic === FOR_YOU_TOPIC ? true : null,
					topicIncluded: topicIncluded === false ? null : topicIncluded,
					resourceType: itemType && itemType.length ? null : LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES,
					itemType,
					topic: topic === FOR_YOU_TOPIC ? null : topic,
					proGrouping: topic === FOR_YOU_TOPIC ? true : null,
					isPro,
					maxWidth: maxWidth ?? 400,
					maxHeight,
					format: 'webp',
				};
				response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap`, { params: createSearchParams(params) });
			}
			if (response.data) {
				const { content, totalPages, totalItems } = response.data as {
					content: PixcapLibraryFile[];
					totalPages: number;
					totalItems: number;
				};
				mutationsWrapper.updateLibraryTemplatesState(context, {
					listOfItems: content,
					pageSize,
					currentPage: content.length ? currentPage + 1 : currentPage,
					totalItems,
					totalPages,
				});
			}
		} catch (err) {
		} finally {
			mutationsWrapper.setIsFetchingLibraryTemplates(context, false);
		}
	},

	async [ActionTypes.FETCH_PACKS_LIST](
		context: ActionContext<any, any>,
		payload: {
			refresh?: boolean;
			page?: number;
			pageSize?: number;
			category?: string;
			search?: string;
			isPro?: boolean;
			hasAnimated?: boolean;
			resourceTypes?: string[];
			tagSearch?: string;
			isBrandKit?: boolean;
			isCurated?: boolean;
			skipStore?: boolean;
		}
	) {
		const {
			refresh,
			page,
			pageSize,
			category,
			search,
			isPro,
			hasAnimated,
			resourceTypes,
			tagSearch,
			isBrandKit,
			isCurated,
			skipStore = false,
		} = payload;
		if (!skipStore) mutationsWrapper.setIsFetchingLibraryPacks(context, true);
		try {
			const currentPage = page;
			if (refresh && !skipStore) {
				mutationsWrapper.resetLibraryPacksState(context);
				mutationsWrapper.updatePackFilter(context, { category, search, isPro, hasAnimated, resourceTypes, tagSearch });
			}
			let response: AxiosResponse<any>;
			if (search) {
				const params = {
					page: currentPage,
					search,
					pageSize,
					resourceType: resourceTypes ?? LIBRARY_EXPLORER_RESOURCE_TYPES,
					isPro,
					hasAnimated,
					isBrandKit,
					isFeatured: isCurated,
					maxWidth: appUtilities.$deviceType === UserDeviceTypes.MOBILE ? 400 : 800,
				};
				response = await httpClient.get(`${ASSETMANAGER_PATH}/library/packs/search`, { params: createSearchParams(params) });
			} else if (resourceTypes && resourceTypes.includes('curated')) {
				const params = {
					page: currentPage,
					pageSize,
					category,
					isPro,
					hasAnimated,
					isBrandKit,
				};
				response = await httpClient.get(`${ASSETMANAGER_PATH}/library/packs/curated`, { params: createSearchParams(params) });
			} else {
				const params = {
					page: currentPage,
					pageSize,
					resourceType: resourceTypes ?? LIBRARY_EXPLORER_RESOURCE_TYPES,
					category,
					isPro,
					hasAnimated,
					tagName: tagSearch,
					isBrandKit,
					isFeatured: isCurated,
					maxWidth: appUtilities.$deviceType === UserDeviceTypes.MOBILE ? 400 : 800,
				};
				response = await httpClient.get(`${ASSETMANAGER_PATH}/library/packs/search`, { params: createSearchParams(params) });
			}
			if (response.data) {
				const { content, totalPages, totalItems } = response.data as {
					content: PixcapLibraryPack[];
					totalPages: number;
					totalItems: number;
				};
				if (skipStore) {
					return response.data;
				} else {
					mutationsWrapper.updateLibraryPacksState(context, {
						listOfItems: content,
						pageSize,
						currentPage,
						totalItems,
						totalPages,
					});
				}
			}
		} catch (err) {
			console.log(err);
		} finally {
			if (!skipStore) mutationsWrapper.setIsFetchingLibraryPacks(context, false);
		}
	},

	async [ActionTypes.FETCH_LIBRARY_TEMPLATE_DETAILS](context: ActionContext<any, any>, templateSlug: string) {
		mutationsWrapper.setIsFetchingTemplateDetails(context, true);
		mutationsWrapper.updateTemplateDetailsState(context, null);

		try {
			const response = await httpClient.get(
				`${ASSETMANAGER_PATH}/library/pixcap/items/slug/${templateSlug}?format=webp&colour=transparent&maxHeight=1024&minHeight=600`
			);
			if (response.data) {
				const content = response.data as PixcapLibraryFile;
				mutationsWrapper.updateTemplateDetailsState(context, content);
			}
		} catch (err) {
			mutationsWrapper.updateTemplateDetailsState(context, null);
		} finally {
			mutationsWrapper.setIsFetchingTemplateDetails(context, false);
		}
	},
	async [ActionTypes.FETCH_LIBRARY_PACK_DETAILS](context: ActionContext<any, any>, packSlug: string) {
		mutationsWrapper.setIsFetchingPackDetails(context, true);
		mutationsWrapper.updatePackDetailsState(context, null);
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/packs/slug/${packSlug}?colour=transparent&maxHeight=400&format=webp`);
			if (response.data) {
				const content = response.data as PixcapLibraryPack;
				mutationsWrapper.updatePackDetailsState(context, content);
			}
		} catch (err) {
			mutationsWrapper.updatePackDetailsState(context, null);
		} finally {
			mutationsWrapper.setIsFetchingPackDetails(context, false);
		}
	},
	async [ActionTypes.FETCH_LIBRARY_SEARCH_SUGGESTIONS](
		context: ActionContext<any, any>,
		payload: { size: number; itemType?: string; resourceType: string; search: string; cancelToken? }
	) {
		const { size, itemType, resourceType, search, cancelToken } = payload;
		mutationsWrapper.setIsFetchingSuggestions(context, true);
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap/autosuggest`, {
				params: {
					size,
					itemType,
					resourceType,
					search,
				},
				cancelToken,
			});
			if (response.data) {
				const { content = [] } = response.data;
				mutationsWrapper.updateSuggestionsList(context, content);
			}
		} catch (e) {
			logger.log('Search suggestion request rejected', e);
		} finally {
			mutationsWrapper.setIsFetchingSuggestions(context, false);
		}
	},

	async [ActionTypes.FETCH_RELATED_MODELS](
		context: ActionContext<any, any>,
		payload: {
			search: string;
			ignoreIds?: string[];
			ignorePackId?: string;
			background?: string;
			maxHeight?: number;
			maxWidth?: number;
			format?: string;
			resourceType?: LIBRARY_RESOURCE_TYPE[];
		}
	) {
		const { search, ignoreIds, ignorePackId, background, maxHeight, resourceType, maxWidth, format } = payload;
		mutationsWrapper.setIsFetchingRelatedModels(context, true);
		mutationsWrapper.updateRelatedModels(context, []); // reset state for new fetch
		try {
			const response = await httpClient.get<{ content: PixcapLibraryFile[] }>(`${ASSETMANAGER_PATH}/library/pixcap`, {
				params: createSearchParams({
					search,
					page: 0,
					pageSize: 20,
					ignoreIds,
					packId: ignorePackId,
					resourceType: resourceType || LIBRARY_RESOURCE_TYPE.MODELS + ',' + LIBRARY_RESOURCE_TYPE.CHARACTERS,
					colour: background,
					maxHeight,
					maxWidth,
					format,
				}),
			});
			if (response.data) {
				const { content } = response.data;
				mutationsWrapper.updateRelatedModels(context, content);
				return content;
			}
		} catch (err) {
		} finally {
			mutationsWrapper.setIsFetchingRelatedModels(context, false);
		}
		return null;
	},
	//  fetchRelatedTemplates
	async [ActionTypes.FETCH_RELATED_TEMPLATES](
		context: ActionContext<any, any>,
		payload: { search: string; ignoreIds?: string[]; ignorePackId?: string; topic?: string; itemType?: string }
	) {
		const { search, ignoreIds, ignorePackId, topic, itemType } = payload;
		mutationsWrapper.setIsFetchingRelatedTemplates(context, true);
		mutationsWrapper.updateRelatedTemplates(context, []); // reset state for new fetch
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap`, {
				params: createSearchParams({
					search,
					page: 0,
					pageSize: 10,
					ignoreIds,
					packId: ignorePackId,
					resourceType: itemType && itemType.length ? null : LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES,
					itemType,
					topic,
					maxWidth: 400,
					format: 'webp',
				}),
			});
			if (response.data) {
				const { content } = response.data as { content: PixcapLibraryFile[] };
				mutationsWrapper.updateRelatedTemplates(context, content);
			}
		} catch (err) {
		} finally {
			mutationsWrapper.setIsFetchingRelatedTemplates(context, false);
		}
	},
	// fetchRelatedPacks
	async [ActionTypes.FETCH_RELATED_PACKS](
		context: ActionContext<any, any>,
		payload: { search: string; isBrandkit?: boolean; isCharacterPack?: boolean }
	) {
		mutationsWrapper.setIsFetchingRelatedPacks(context, true);
		mutationsWrapper.updateRelatedPacks(context, []); // reset state for new fetch
		try {
			const { search, isBrandkit = false, isCharacterPack = false } = payload;
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/packs/search`, {
				params: createSearchParams({
					search,
					isBrandkit,
					page: 0,
					pageSize: 12,
					resourceType: isBrandkit
						? LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES
						: isCharacterPack
						? LIBRARY_RESOURCE_TYPE.CHARACTERS
						: LIBRARY_EXPLORER_RESOURCE_TYPES.filter((item) => item !== LIBRARY_RESOURCE_TYPE.CHARACTERS),
					maxWidth: appUtilities.$deviceType === UserDeviceTypes.MOBILE ? 400 : 800,
				}),
			});
			if (response.data) {
				const { content } = response.data;
				mutationsWrapper.updateRelatedPacks(context, content);
			}
		} catch (err) {
		} finally {
			mutationsWrapper.setIsFetchingRelatedPacks(context, false);
		}
	},
	async [ActionTypes.FETCH_PRESIGNED_FILE](context: ActionContext<any, any>, fileId: string) {
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/presigned/${fileId}`);
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			logger.log('Search suggestion request rejected', e);
		}
	},

	[ActionTypes.SET_LIBRARY_STATE_FROM_ROUTE](context: ActionContext<any, any>, routeContext) {
		const { route, from } = routeContext;

		const routeName = route.name;
		const showOnlyPro = route.query[LibraryQueryTypes.SHOW_ONLY_PRO];
		const showType = route.query[LibraryQueryTypes.TYPE];
		const exploreTab = route.query[LibraryQueryTypes.VIEW];
		const tag = route.query[LibraryQueryTypes.TAG];
		const isPro = showOnlyPro === LibraryFilePlans.PRO ? true : showOnlyPro === LibraryFilePlans.FREE ? false : null;
		const isAnimatedOnly = showType === LibraryFileType.ANIMATED ? true : showType === LibraryFileType.STATIC ? false : null;
		const tab = route.params.tab as any;
		const packFilter = context.state.packFilter;
		const templateFilter = context.state.templateFilter;

		let search = route.params[LibraryQueryTypes.SEARCH_QUERY] as string;
		if (search) {
			search = transformSearchWord(search, true);
		}

		switch (routeName) {
			case 'LibraryExploreList': {
				mutationsWrapper.updateLibrarySearch(context, null);
				mutationsWrapper.updateShowOnlyAnimatedFiles(context, null);
				mutationsWrapper.updateFilterDesignSize(context, null);
				mutationsWrapper.updateShowOnlyProFiles(context, null);
				mutationsWrapper.updateShowOnlyProPacks(context, null);
				if (exploreTab && Object.values(LIBRARY_EXPLORE_TABS).includes(exploreTab)) {
					mutationsWrapper.setExplorerTab(context, exploreTab);
				} else {
					mutationsWrapper.setExplorerTab(context, LIBRARY_EXPLORE_TABS.MOCKUPS);
				}
				break;
			}
			case 'LibraryPage': {
				mutationsWrapper.updateLibrarySearch(context, null);
				mutationsWrapper.updateShowOnlyProFiles(context, isPro);
				mutationsWrapper.updateShowOnlyProPacks(context, isPro);
				mutationsWrapper.updateShowOnlyAnimatedFiles(context, isAnimatedOnly);
				if (tag) {
					mutationsWrapper.updatePackFilter(context, { ...packFilter, tagSearch: tag });
				}
				if (tab) mutationsWrapper.setExplorerTab(context, tab);
				else mutationsWrapper.setExplorerTab(context, LIBRARY_TAB.THREE_D_PACKS);
				break;
			}
			case 'LibraryExploreSearchList': {
				mutationsWrapper.updateLibrarySearch(context, search);
				mutationsWrapper.updateShowOnlyAnimatedFiles(context, isAnimatedOnly);
				mutationsWrapper.updateShowOnlyProFiles(context, isPro);
				mutationsWrapper.updateShowOnlyProPacks(context, isPro);
				switch (tab) {
					case SEARCH_ROUTES.PACK_ALL_ASSET:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.PACKS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.ALL_ASSETS);
						mutationsWrapper.updatePackFilter(context, { ...packFilter, resourceTypes: LIBRARY_EXPLORER_RESOURCE_TYPES });
						break;
					case SEARCH_ROUTES.PACK_ICONS:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.PACKS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.THREE_D_ICONS);
						mutationsWrapper.updatePackFilter(context, {
							...packFilter,
							resourceTypes: [
								LIBRARY_RESOURCE_TYPE.MODELS,
								LIBRARY_RESOURCE_TYPE.FRAMES,
								LIBRARY_RESOURCE_TYPE.TEXT,
								LIBRARY_RESOURCE_TYPE.TEXT_SHAPES,
								LIBRARY_RESOURCE_TYPE.SHAPES,
							],
						});
						break;
					case SEARCH_ROUTES.PACK_CHARACTERS:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.PACKS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.THREE_D_CHARACTERS);
						mutationsWrapper.updatePackFilter(context, {
							...packFilter,
							resourceTypes: [LIBRARY_RESOURCE_TYPE.CHARACTERS],
						});
						break;
					case SEARCH_ROUTES.PACK_MOCKUPS:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.PACKS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.MOCKUPS);
						mutationsWrapper.updatePackFilter(context, { ...packFilter, resourceTypes: [LIBRARY_RESOURCE_TYPE.DESIGN_TEMPLATES] });
						break;
					case SEARCH_ROUTES.ITEM_ALL_ASSETS:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.ITEMS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.ALL_ASSETS);
						break;
					case SEARCH_ROUTES.ITEM_ELEMENTS:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.ITEMS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.THREE_D_ICONS);
						mutationsWrapper.setSearchItemResourceType(context, LIBRARY_RESOURCE_TYPE.ELEMENTS);
						break;
					case SEARCH_ROUTES.ITEM_CHARACTERS:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.ITEMS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.THREE_D_CHARACTERS);
						mutationsWrapper.setSearchItemResourceType(context, LIBRARY_RESOURCE_TYPE.CHARACTERS);
						break;
					case SEARCH_ROUTES.ITEM_MOCKUPS:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.ITEMS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.MOCKUPS);
						break;
					default:
						mutationsWrapper.updateShowPackOrElement(context, SEARCH_TAB.ITEMS);
						mutationsWrapper.setSearchItemType(context, SEARCH_ITEM_TYPE.ALL_ASSETS);
				}
				break;
			}
		}
	},

	// TOPICS
	async [ActionTypes.FETCH_USECASE_TOPICS](context: ActionContext<any, any>, keepInStore: boolean = false) {
		let topics = [];
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/topics`, {
				params: {
					origin: 'usecase',
				},
			});
			if (response.data?.content) {
				if (keepInStore) {
					mutationsWrapper.updateUsecaseTopics(context, response.data);
				} else {
					topics = response.data.content;
				}
			}
		} catch (err) {
			logger.log('Error while getting explore topics', err);
		}

		return topics;
	},

	async [ActionTypes.FETCH_TOPICS_BY_SIZE](context: ActionContext<any, any>) {
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/topics`, {
				params: {
					origin: 'templates',
				},
			});
			if (response.data?.content) {
				return response.data.content;
			}
		} catch (e) {
			logger.log('Error while getting topics by size', e);
			return [];
		}
	},

	async [ActionTypes.FETCH_TAGS](
		context: ActionContext<any, any>,
		payload: {
			itemType?: string[];
		}
	) {
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap/tags`, {
				params: {
					pageSize: 20,
					...payload,
				},
			});
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			logger.log('Error while getting topic tags', e);
			return [];
		}
	},

	async [ActionTypes.FETCH_USECASE_TOPIC_TAGS](context: ActionContext<any, any>, topicId?: string) {
		try {
			// All topic tags
			let fetchUrl = `${ASSETMANAGER_PATH}/topics/tags`;
			if (topicId) {
				// Tags by topic id
				fetchUrl = `${ASSETMANAGER_PATH}/topics/${topicId}/tags`;
			}
			const response = await httpClient.get(fetchUrl, {
				params: {
					pageSize: 20,
				},
			});
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			logger.log('Error while getting topic tags', e);
		}
	},

	async [ActionTypes.FETCH_SUGGESTED_KEYWORDS](context: ActionContext<any, any>, resourceType?: LIBRARY_RESOURCE_TYPE) {
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap/popular`, {
				params: {
					page: 0,
					pageSize: 20,
					resourceType: resourceType || null,
				},
			});
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			logger.log('Error while getting suggested keywords', e);
		}
	},

	async [ActionTypes.FETCH_RECENT_SEARCH_KEYWORDS](context: ActionContext<any, any>) {
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap/history`);
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			logger.log('Error while getting recent keywords', e);
		}
	},

	async [ActionTypes.UPDATE_RECENT_SEARCH_KEYWORDS](context: ActionContext<any, any>, payload: { keyword: string; action: string }) {
		try {
			const { keyword, action } = payload;
			await httpClient.put(`${ASSETMANAGER_PATH}/library/pixcap/history`, {
				term: keyword,
				action,
			});
		} catch (e) {
			logger.log('Error while getting recent keywords', e);
		}
	},

	async [ActionTypes.FETCH_RELATED_KEYWORDS](context: ActionContext<any, any>, payload: { type: string; keyword: string }) {
		try {
			const { type, keyword } = payload;
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/library/pixcap/relevant-searches`, {
				params: {
					page: 0,
					pageSize: 30,
					search: keyword,
					type,
				},
			});
			if (response.data) {
				return response.data;
			}
		} catch (e) {
			logger.log('Error while getting related keywords', e);
		}
	},
};
