export default ({ app, route }) => {
	const isLocalActive = !!route.params.locale;
	const isRouteSupportLocale = route.matched?.some((record) => record.path.includes(':locale'));
	const excludedRoute = route.name === 'LibraryPage' && !!route.query.tag;
	const trimValue = isLocalActive && isRouteSupportLocale ? 3 : 0;
	const hrefLangLink = [
		{
			rel: 'alternate',
			hreflang: 'x-default',
			href: `${process.env.ROOT_URL}${route.fullPath.substring(trimValue)}`,
		},
		{
			rel: 'alternate',
			hreflang: 'en',
			href: `${process.env.ROOT_URL}${route.fullPath.substring(trimValue)}`,
		},
		{
			rel: 'alternate',
			hreflang: 'es',
			href: `${process.env.ROOT_URL}/es${route.fullPath.substring(trimValue)}`,
		},
		{
			rel: 'alternate',
			hreflang: 'de',
			href: `${process.env.ROOT_URL}/de${route.fullPath.substring(trimValue)}`,
		},
	];
	app.head = {
		...app.head,
		htmlAttrs: {
			lang: route.params.locale,
		},
	};
	if (!excludedRoute) {
		app.head = {
			...app.head,
			link: [...(app.head.link || []), ...hrefLangLink],
		};
	}
};
